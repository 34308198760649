import { gql } from '@apollo/client'
import { Group } from '@blissbook/common/group'
import Handbook from '@blissbook/common/handbook'
import { Integration } from '@blissbook/common/integration'
import Organization from '@blissbook/common/organization'
import { Session } from '@blissbook/common/session'
import {
  GroupFragmentDoc,
  OrganizationFragmentDoc,
} from '@blissbook/ui/application/graph'
import isArray from 'lodash/isArray'
import mapValues from 'lodash/mapValues'

export const GET_PEOPLE_COUNTS = gql`
  query getPeopleCounts {
    handbooks {
      id
      viewersCount
    }
    groups {
      ...group
    }
    organization {
      ...organization
    }
  }

  ${GroupFragmentDoc}
  ${OrganizationFragmentDoc}
`

// Query ----------------------------------------------------------------------

const QUERY_RESPONSE_TYPES = {
  groups: Group,
  handbook: Handbook,
  handbooks: Handbook,
  integrations: Integration,
  organization: Organization,
  session: Session,
}

export const parseQueryResponseData = (data) =>
  mapValues(data, (json, key) => {
    const Type = QUERY_RESPONSE_TYPES[key]
    if (!Type) return json
    return isArray(json) ? json.map(Type.fromJSON) : Type.fromJSON(json)
  })
