import type { Permission } from '../types'
import { mapById } from '../util'

export const teamPermissions: Permission[] = [
  {
    id: 'events.view',
    label: 'View Events',
    description: 'User can view events on the team',
  },
  {
    id: 'properties.view',
    label: 'View Properties',
    description: 'User can view properties on the team',
  },
  {
    id: 'teammates.view',
    label: 'View Teammates',
    description: 'User can view teammates / handbook collaborators',
  },
  {
    id: 'lawChanges.view',
    label: 'View Law Changes',
    description: 'User can view law changes',
  },
  {
    id: 'policyLibrary.view',
    label: 'View Policy Library',
    description: 'User can view the policy library',
  },
  {
    id: 'assets.manage',
    label: 'Manage Assets',
    description: 'User can manage assets on the team',
  },
  {
    id: 'people.report',
    label: 'Report on People',
    description: 'User can report on people on the team',
  },
  {
    id: 'teammates.edit',
    label: 'Edit Teammates',
    description: 'User can edit teammates / handbook collaborators',
  },
  {
    id: 'documents.create',
    label: 'Create Documents',
    description: 'User can create documents',
  },
  {
    id: 'documents.order',
    label: 'Order Documents',
    description: 'User can order documents within the Policy Library',
  },
  {
    id: 'handbooks.create',
    label: 'Create Handbook',
    description: 'User can create handbooks',
  },
  {
    id: 'properties.manage',
    label: 'Manage Properties',
    description: 'User can manage properties on the team',
  },
]

export const teamPermissionsById = mapById(teamPermissions)
