import { teamSuperBrandingRole } from '../team/teamRoles'
import type { Role } from '../types'
import { mapById } from '../util'
import { organizationPermissions } from './organizationPermissions'

// Api Key permissions
export const apiKeyRole: Role = {
  label: 'API Key',
  description: 'Permissions granted to the api key',
  permissionIds: ['api.export'],
}

// Permissions granted to all orgCollaborators that have a role on any resource in Blissbook
export const orgCollaboratorRole: Role = {
  label: 'Org Collaborator',
  description:
    'Permissions granted to users who have any role on the organization',
  permissionIds: ['organization.view', 'people.query'],
}

export type OrganizationAdminRoleId =
  | 'billingAdmin'
  | 'brandingAdmin'
  | 'orgAdmin'
  | 'techAdmin'

export type OrganizationAdminRole = Role & {
  id: OrganizationAdminRoleId
  color: string
  homeUrl?: string
}

export const orgAdminRole: OrganizationAdminRole = {
  id: 'orgAdmin',
  label: 'Org Admin',
  description:
    'Can do everything: manage all organization settings, subscription details, and all members.',
  color: 'red',
  permissionIds: [
    ...organizationPermissions
      .map((p) => p.id)
      .filter((p) => !['groups.edit'].includes(p)),
    'handbook.query',
    'teammates.view',
    'teammates.edit',
  ],
}

export const billingAdminRole: OrganizationAdminRole = {
  id: 'billingAdmin',
  label: 'Billing',
  description: 'Can view subscription information.',
  color: 'sunshine',
  homeUrl: '/subscription',
  permissionIds: [...orgCollaboratorRole.permissionIds, 'billing.view'],
}

export const brandingAdminRole: OrganizationAdminRole = {
  id: 'brandingAdmin',
  label: 'Branding',
  description:
    'Can edit Email Branding, Email Templates, and Audience Dashboard Branding.',
  color: 'blurple',
  permissionIds: [
    ...orgCollaboratorRole.permissionIds,
    ...teamSuperBrandingRole.permissionIds.filter(
      (p) => !['handbook.draft.editCss'].includes(p),
    ),
    'assets.manage',
    'organization.brand',
  ],
}

export const techAdminRole: OrganizationAdminRole = {
  id: 'techAdmin',
  label: 'Tech',
  description:
    'Can edit all Organization Members. They can also edit the Custom Domain, Email Sending Domain, SSO, and people sync integrations.',
  color: 'aqua',
  homeUrl: '/organization',
  permissionIds: [
    ...orgCollaboratorRole.permissionIds,
    'handbook.query',
    'handbook.performTech',
    'integrations.edit',
    'people.edit',
    'people.export',
    'people.view',
  ],
}

export const organizationAdminRoles: OrganizationAdminRole[] = [
  brandingAdminRole,
  billingAdminRole,
  techAdminRole,
  orgAdminRole,
]

export const organizationAdminRolesReverse = [
  ...organizationAdminRoles,
].reverse()

export const organizationAdminRolesById = mapById(organizationAdminRoles)
