import { documentCollaboratorTeamRole } from '../document/documentRoles'
import { teamCollaboratorRole } from '../team/teamRoles'
import type { Role } from '../types'
import {
  type OrganizationAdminRoleId,
  organizationAdminRolesById,
} from './organizationRoles'

export type Person = {
  id: number
  adminRoleId: OrganizationAdminRoleId | null
  hasDocumentRole: boolean
  hasHandbookRole: boolean
  hasTeamRole: boolean
  isTeamCollaborator: boolean
}

export function getPersonRoles(person: Person) {
  const roles: Role[] = []

  const adminRole = organizationAdminRolesById.get(person.adminRoleId)
  if (adminRole) roles.push(adminRole)

  if (person.isTeamCollaborator) roles.push(teamCollaboratorRole)
  if (person.hasDocumentRole) roles.push(documentCollaboratorTeamRole)

  return roles
}
