// To add a feature flag, add it to the featureFlags array below.
// Then run: yarn run graphql:generate
export type FeatureFlagState = 'beta' | 'dev'

export type FeatureFlag = {
  id: string
  state?: FeatureFlagState
  label: string
  description: string
}

// After adding a flag here run: yarn run graphql:generate
export const featureFlags = [
  {
    id: 'customHtml',
    state: 'dev',
    label: 'Allow Custom HTML on Handbooks',
    description: 'Super Admins can add custom HTML to handbooks',
  },
  {
    id: 'allowCsvSync',
    label: 'Allow Employee Sync via SFTP',
    description: 'Allows customers to sync an employee CSV file via SFTP',
  },
  {
    id: 'allowMergeDev',
    label: 'Advanced HRIS Integrations',
    description: 'Allows access to Merge.dev integrations',
  },
  {
    id: 'allowEmployeeIdAuth',
    label: 'Employee ID + Verification',
    description: 'Employees can authenticate via SMS Text or Voice Call',
  },
  {
    id: 'importHandbooks',
    label: 'Import Handbooks',
    description: 'Super Admins can import handbooks',
  },
  {
    id: 'allowKioskMode',
    label: 'Allow Kiosk Mode',
    description: 'Allow kiosk mode to be enabled',
  },
  {
    id: 'allowManagers',
    label: 'Allow Managers',
    description: 'Allow manager participation to be enabled',
  },
  {
    id: 'allowMultipleSamlSsos',
    label: 'Allow multiple SAMLs',
    description: 'Allow multiple SAML Single Sign-Ons to be installed',
  },
  {
    id: 'hideOverallGrade',
    label: 'Hide Overall Grade',
    description: 'Hide the overall grade in the documentation center',
  },
  {
    id: 'noLaunchCountdown',
    label: 'No Launch Countdown',
    description: 'Show a simple button in favor of the launch countdown',
  },
  {
    id: 'showFriendsPage',
    label: 'Show Friends Page',
    description: 'Show friends page, even if there are no friends :(',
  },
  {
    id: 'hideLawChanges',
    label: 'Hide Law Changes',
    description: 'Hide the law changes page',
  },
  {
    id: 'showLawCenter',
    state: 'dev',
    label: 'Law Center',
    description: 'Show the labor law center CMS',
  },
  {
    id: 'showPolicyLibrary',
    label: 'Policy Library',
    description: 'Show the policy library',
  },
  {
    id: 'isSourceOrganization',
    label: 'Agency',
    description:
      'Mark this organization as an agency that can distribute source content',
  },
  {
    id: 'allowPolicyWorkflows',
    state: 'beta',
    label: 'Policy Approval Workflows',
    description: 'Allow policy approval workflows with granual access control',
  },
  {
    id: 'forceCustomPricing',
    label: 'Custom Pricing',
    description: 'Force customer to talk to customer success for pricing',
  },
] as FeatureFlag[]

export const betaFeatureFlags = featureFlags.filter(
  (flag) => flag.state === 'beta',
)
export const devFeatureFlags = featureFlags.filter(
  (flag) => flag.state === 'dev',
)
export const liveFeatureFlags = featureFlags.filter((flag) => !flag.state)
