import type { Permission } from '../types'
import { mapById } from '../util'

export const organizationPermissions: Permission[] = [
  {
    id: 'organization.view',
    label: 'View Organization',
    description: `User can access Blissbook's CMS`,
  },
  {
    id: 'people.query',
    label: 'Query People',
    description: 'User can search for people on the organization',
  },
  {
    id: 'people.view',
    label: 'View People',
    description: 'User can view people on the organization',
  },
  {
    id: 'people.edit',
    label: 'Edit People',
    description: 'User can modify people on the organization',
  },
  {
    id: 'people.export',
    label: 'Export People',
    description: 'User can export people on the organization',
  },
  {
    id: 'people.notify',
    label: 'Notify People',
    description: 'User can sends emails to people on the organization',
  },
  {
    id: 'orgCollaborators.view',
    label: 'View Org Collaborators',
    description: 'User can view org collaborators',
  },
  {
    id: 'integrations.edit',
    label: 'Edit Integrations',
    description: 'User can modify integrations',
  },
  {
    id: 'billing.edit',
    label: 'Edit Billing',
    description: 'User can edit billing',
  },
  {
    id: 'billing.view',
    label: 'View Billing',
    description: 'User can view billing',
  },
  {
    id: 'groups.edit',
    label: 'Edit Groups',
    description: 'User can edit groups',
  },
  {
    id: 'handbooks.order',
    label: 'Order Handbooks',
    description: 'User can order handbooks',
  },
  {
    id: 'organization.admin',
    label: 'Admin Organization',
    description: 'User can administer the organization',
  },
  {
    id: 'organization.brand',
    label: 'Brand Organization',
    description: 'User can brand the organization',
  },
  {
    id: 'api.export',
    label: 'Export via API',
    description: 'User can export data via API',
  },
  {
    id: 'savedSegments.admin',
    label: 'Admin Saved Segments',
    description: 'User can administer saved segments',
  },
  {
    id: 'assets.manage',
    label: 'Manage Assets',
    description: 'User can query, upload, and manage PDF and image assets',
  },
]

export const organizationPermissionsById = mapById(organizationPermissions)
