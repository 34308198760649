import { getFeatureFlagIds } from '@blissbook/lib/features'
import { useFeaturesQuery } from '@blissbook/ui/application/graph'
import { useMemo } from 'react'

// Hook to get the array of enabled feature flag ids
export function useFeatureFlagIds(): Set<string> {
  const { data } = useFeaturesQuery()

  return useMemo(() => {
    if (!data) return new Set()
    return getFeatureFlagIds(data.featureFlagIds, data.chargebeeSubscription)
  }, [data])
}
