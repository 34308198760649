import { gql } from '@apollo/client'
import { storeAssetUrls } from '@blissbook/ui/application/assets'
import {
  AssetFragmentDoc,
  HandbookAssetFragmentDoc,
} from '@blissbook/ui/application/graph'
import { client } from '@blissbook/ui/util/apollo'

export const getHandbookAssets = async (handbookId, variables) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query handbookAssetsQuery($handbookId: Int!, $type: AssetType) {
        handbook(id: $handbookId) {
          id
          assets(type: $type) {
            ...handbookAsset
          }
        }
      }

      ${HandbookAssetFragmentDoc}
    `,
    variables: { ...variables, handbookId },
  })
  const { assets } = data.handbook
  storeAssetUrls(assets)
  return assets
}

export const getAssets = async (variables) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query getAssets($tag: String, $type: AssetType) {
        assets(tag: $tag, type: $type) {
          ...asset
        }
      }

      ${AssetFragmentDoc}
    `,
    variables,
  })
  const { assets } = data
  storeAssetUrls(assets)
  return assets
}

export const archiveHandbookAsset = async (handbookId, assetId) =>
  client.mutate({
    mutation: gql`
      mutation archiveHandbookAsset($handbookId: Int!, $assetId: Int!) {
        archiveHandbookAsset(handbookId: $handbookId, assetId: $assetId)
      }
    `,
    variables: { handbookId, assetId },
  })

export const archiveAsset = async (assetId) =>
  client.mutate({
    mutation: gql`
      mutation archiveAsset($assetId: Int!) {
        archiveAsset(assetId: $assetId)
      }
    `,
    variables: { assetId },
  })
