import type { Asset } from '@blissbook/ui/application/graph'

type StoreAsset = Pick<Asset, 'path' | 'url'>

function getAssetKey(url: string) {
  return url.toLowerCase()
}

export function resolveAssetUrl(url?: string) {
  if (!url) return
  const key = getAssetKey(url)
  return sessionStorage.getItem(key) || url
}

export function storeAssetUrl(asset: StoreAsset) {
  const key = getAssetKey(asset.path)
  sessionStorage.setItem(key, asset.url)
}

export function storeAssetUrls(assets: StoreAsset[]) {
  for (const asset of assets) {
    storeAssetUrl(asset)
  }
}
