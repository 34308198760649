import { documentPermissions } from '../document/documentPermissions'
import { documentEditorRole } from '../document/documentRoles'
import { handbookPermissions } from '../handbook/handbookPermissions'
import { organizationPermissions } from '../organization/organizationPermissions'
import { brandingAdminRole } from '../organization/organizationRoles'
import { teamPermissions } from '../team/teamPermissions'
import type { Role } from '../types'
import { mapById } from '../util'

export type SuperUserRoleId =
  | 'superUser'
  | 'superBrander'
  | 'superAdmin'
  | 'superOwner'

export type SuperUserRole = Role & {
  id: SuperUserRoleId | null
}

export const superAdminRole: SuperUserRole = {
  id: 'superAdmin',
  label: 'Super Admin',
  description: 'User can administer everything in Blissbook',
  permissionIds: [
    'blissbook.admin',
    'superUsers.view',
    ...organizationPermissions
      .map((p) => p.id)
      .filter((id) => id !== 'people.notify'),
    ...teamPermissions.map((p) => p.id),
    ...documentPermissions
      .map((p) => p.id)
      .filter((id) => !['documentReviews.manage'].includes(id)),
    ...handbookPermissions
      .map((p) => p.id)
      .filter(
        (id) =>
          !['handbook.notifyPeople', 'handbook.recordSignature'].includes(id),
      ),
  ],
}

export const superOwnerRole: SuperUserRole = {
  id: 'superOwner',
  label: 'Super Owner',
  description:
    'User can administer everything in Blissbook and modify super admins',
  permissionIds: [...superAdminRole.permissionIds, 'superUsers.edit'],
}

export const superBranderRole: SuperUserRole = {
  id: 'superBrander',
  label: 'Super Brander',
  description: 'User can brand everything in Blissbook',
  permissionIds: [
    ...brandingAdminRole.permissionIds,
    ...documentEditorRole.permissionIds,
    'policyLibrary.view',
    'handbook.draft.editCss',
  ],
}

export const superUserRole: SuperUserRole = {
  id: 'superUser',
  label: 'Super User',
  description: 'User can access all organizations in Blissbook',
  permissionIds: [
    'billing.view',
    'handbook.query',
    'handbook.draft.view',
    'handbook.draft.preview',
    'handbook.history.view',
    'handbook.report',
    'handbook.live.view',
    'handbook.sharing.view',
    'lawChanges.view',
    'organization.view',
    'orgCollaborators.view',
    'people.query',
    'people.report',
    'people.view',
    'properties.view',
    'teammates.view',
  ],
}

export const superUserRoles = [
  superOwnerRole,
  superAdminRole,
  superBranderRole,
  superUserRole,
]

export const superUserRolesById = mapById(superUserRoles)

export function getSuperUserRole(roleId: SuperUserRoleId) {
  return superUserRolesById.get(roleId) || superUserRole
}
