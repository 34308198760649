import Handbook from '@blissbook/common/handbook'
import { HandbookNotification } from '@blissbook/common/handbook/notification'
import HandbookRecipient from '@blissbook/common/handbook/recipient'
import { getDueAt } from '@blissbook/common/handbook/util/signatures'
import { storeAssetUrls } from '@blissbook/ui/application/assets'
import { getIn, patchIn } from '@blissbook/ui/util/store'
import produce from 'immer'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import remove from 'lodash/remove'
import set from 'lodash/set'
import sortBy from 'lodash/sortBy'
import without from 'lodash/without'

const patchJson = (byId, id, props, klass) => {
  if (byId[id]) {
    if (klass) props = klass.mapJSON(props)
    patchIn(byId[id], props, klass?.patchKeys)
  } else {
    byId[id] = klass ? klass.fromJSON(props) : props
  }
}

const patchJsonById = (draft, byIdPath, idColumn, array, klass) => {
  const prevById = get(draft, byIdPath, {})
  const byId = {}
  for (const props of array) {
    const id = props[idColumn]
    byId[id] = prevById[id]
    patchJson(byId, id, props, klass)
  }
  set(draft, byIdPath, byId)
}

// AUDIENCE DASHBOARD SETTINGS

const reduceAudienceDashboardSettings = (draft, json) => {
  draft.audienceDashboardSettings = json
}

export const patchAudienceDashboardSettings = produce((draft, { changes }) => {
  patchIn(draft.audienceDashboardSettings, changes)
})

// CUSTOM DOMAINS

export const setCustomDomain = produce((draft, { customDomain }) => {
  draft.customDomain = customDomain
})

export const removeCustomDomain = produce((draft) => {
  // biome-ignore lint/performance/noDelete: app relies on this behavior insted of using undefined
  delete draft.customDomain
})

// EMAIL DOMAINS

const reduceEmailDomains = (draft, emailDomains) => {
  patchJsonById(draft, 'emailDomainsById', 'id', emailDomains)
}

export const setEmailDomain = produce((draft, { emailDomain }) => {
  patchJson(draft.emailDomainsById, emailDomain.id, emailDomain)
})

export const setEmailDomainStatus = produce((draft, { id, status }) => {
  patchJson(draft.emailDomainsById, id, status)
})

export const deleteEmailDomain = produce((draft, { id }) => {
  delete draft.emailDomainsById[id]
})

// EMAIL SETTINGS

const reduceEmailSettings = (draft, json) => {
  draft.emailSettings = json
}

export const patchEmailSettings = produce((draft, { changes }) => {
  patchIn(draft.emailSettings, changes)
})

// EMAIL TEMPLATES

const reduceEmailTemplates = (draft, emailTemplates) => {
  draft.emailTemplatesById = keyBy(emailTemplates, 'id')
}

export const setEmailTemplate = produce((draft, { emailTemplate }) => {
  draft.emailTemplatesById[emailTemplate.id] = emailTemplate
})

// GROUPS

const reduceGroups = (draft, groups) => {
  draft.groupsById = keyBy(groups, 'id')
}

export const setGroup = produce((draft, { group }) => {
  draft.groupsById[group.id] = group
})

export const updateGroup = produce((draft, { groupId, changes }) => {
  patchIn(draft.groupsById[groupId], changes)
})

export const archiveGroup = produce((draft, { groupId }) => {
  // Archive the group
  patchIn(draft.groupsById[groupId], {
    archived: true,
  })

  // Delete from person groupIds
  const { peopleById } = draft
  if (peopleById) {
    Object.keys(peopleById).forEach((personId) => {
      const person = peopleById[personId]
      if (person.groupIds.includes(groupId)) {
        person.groupIds = without(person.groupIds, groupId)
      }
    })
  }
})

// HANDBOOKS

const getHandbook = (draft, handbookId) => draft.handbooksById[handbookId]

const reduceHandbooks = (draft, handbooks) => {
  patchJsonById(draft, 'handbooksById', 'id', handbooks, Handbook)
}

const reduceHandbook = (draft, json) => {
  const { assets } = json
  patchJson(draft.handbooksById, json.id, json, Handbook)
  if (assets) storeAssetUrls(assets)
}

export const setHandbook = produce((draft, { handbook }) => {
  const { assets } = handbook
  draft.handbooksById[handbook.id] = handbook
  if (assets) storeAssetUrls(assets)
})

const patchInHandbook = (draft, handbookId, json) => {
  patchJson(draft.handbooksById, handbookId, json, Handbook)
}

const setHandbookModified = (draft, handbookId, ctx) =>
  patchInHandbook(draft, handbookId, {
    lastModifiedAt: ctx.timestamp,
    lastModifiedBy: ctx.personId,
    updatedAt: ctx.timestamp,
  })

export const patchHandbook = produce((draft, { handbookId, changes, ctx }) => {
  patchInHandbook(draft, handbookId, changes)
  if (ctx) setHandbookModified(draft, handbookId, ctx)
})

export const setHandbookTask = produce((draft, { handbookId, task }) => {
  const handbook = getHandbook(draft, handbookId)
  handbook.tasks = handbook.tasks.map((t) => (t.id === task.id ? task : t))
})

export const setHandbookPositions = produce((draft, { handbookIds }) => {
  for (let index = 0; index < handbookIds.length; index++) {
    const handbookId = handbookIds[index]
    const handbook = getHandbook(draft, handbookId)
    handbook.position = index + 1
  }
})

export const deleteHandbook = produce((draft, { handbookId }) => {
  delete draft.handbooksById[handbookId]
})

// Handbook Fonts

export const addHandbookFont = produce((draft, { handbookId, font }) => {
  const handbook = getHandbook(draft, handbookId)
  handbook.fonts.push(font)
})

// Handbook Preview Links

export const addHandbookPreviewLink = produce(
  (draft, { handbookId, previewLink }) => {
    const handbook = getHandbook(draft, handbookId)
    handbook.previewLinks = sortBy(
      [...handbook.previewLinks, previewLink],
      'personId',
    )
  },
)

export const setHandbookPreviewLinks = produce(
  (draft, { handbookId, previewLinks }) => {
    const handbook = getHandbook(draft, handbookId)
    handbook.previewLinks = previewLinks
  },
)

export const removeHandbookPreviewLink = produce(
  (draft, { handbookId, personId }) => {
    const handbook = getHandbook(draft, handbookId)
    handbook.previewLinks = handbook.previewLinks.filter(
      (link) => link.personId !== personId,
    )
  },
)

// HANDBOOK RECIPIENTS

const getHandbookRecipient = (draft, personId, handbookId) =>
  getIn(draft, `handbookRecipients[${personId}][${handbookId}]`)

const patchHandbookRecipient = (draft, personId, handbookId, json) => {
  draft.handbookRecipients = draft.handbookRecipients || {}
  const byPersonId = draft.handbookRecipients

  byPersonId[personId] = byPersonId[personId] || {}
  const byHandbookId = byPersonId[personId]

  if (byHandbookId[handbookId]) {
    const changes = HandbookRecipient.mapJSON(json)
    patchIn(byHandbookId[handbookId], changes)
  } else {
    byHandbookId[handbookId] = HandbookRecipient.fromJSON(json)
  }
}

export const addHandbookRecipients = produce((draft, recipients) => {
  for (const recipient of recipients) {
    const { handbookId, personId } = recipient
    patchHandbookRecipient(draft, personId, handbookId, recipient)
  }
})

export const addHandbookNotification = produce((draft, json) => {
  const { handbookId, personId } = json
  const prev = getHandbookRecipient(draft, personId, handbookId)
  if (!prev) return

  // Add to the recipient
  const { notifications } = prev
  const notification = HandbookNotification.fromJSON(json)
  patchHandbookRecipient(draft, personId, handbookId, {
    lastNotifiedAt: notification.createdAt,
    notifications: notifications ? [...notifications, notification] : undefined,
  })
})

export const addHandbookSignature = produce(
  (draft, { handbookId, personId, signature }) => {
    // Find the existing recipient (if any)
    const prev = getHandbookRecipient(draft, personId, handbookId)
    if (!prev) return

    // Add to the recipient
    patchHandbookRecipient(draft, personId, handbookId, {
      isSignatureRequired: false,
      lastSignedAt: signature.signedAt,
      lastSignedVersion: signature.handbookVersion,
      maxScore: 4,
      score: 4,
    })
  },
)

export const deleteHandbookSignature = produce(
  (draft, { handbookId, personId }) => {
    // Find the existing recipient (if any)
    const prev = getHandbookRecipient(draft, personId, handbookId)
    if (!prev) return

    // Remove from recipeint signatures
    patchHandbookRecipient(draft, personId, handbookId, {
      isSignatureRequired: true,
      lastSignedAt: undefined,
      lastSignedVersion: undefined,
    })
  },
)

export const addSignatureDueDateOverride = produce(
  (draft, { handbookId, personId, signatureDueDateOverride }) => {
    // Find the existing recipient (if any)
    const prev = getHandbookRecipient(draft, personId, handbookId)
    if (!prev) return

    // Add to the recipient
    const { dueDate } = signatureDueDateOverride
    const { signatureDueDateOverrides } = prev
    patchHandbookRecipient(draft, personId, handbookId, {
      signatureDueDate: dueDate,
      signatureDueAt: getDueAt(dueDate),
      signatureDueDateOverrides: signatureDueDateOverrides
        ? [...signatureDueDateOverrides, signatureDueDateOverride]
        : undefined,
    })
  },
)

// HANDBOOK REMINDERS

const reduceHandbookReminders = (draft, handbookReminders) => {
  draft.handbookRemindersById = keyBy(handbookReminders, 'id')
}

export const setHandbookReminder = produce((draft, { reminder }) => {
  draft.handbookRemindersById[reminder.id] = reminder
})

export const deleteHandbookReminder = produce((draft, { id }) => {
  delete draft.handbookRemindersById[id]
})

// INTEGRATIONS

const reduceIntegrations = (draft, integrations) => {
  draft.integrationsById = keyBy(integrations, 'id')
}

export const setIntegration = produce((draft, { integration }) => {
  draft.integrationsById[integration.id] = integration
})

export const deleteIntegration = produce((draft, { integrationId }) => {
  delete draft.integrationsById[integrationId]
})

// MANAGER DIGEST SETTINGS

export const setManagerDigestSettings = produce(
  (draft, { managerDigestSettings }) => {
    draft.managerDigestSettings = managerDigestSettings
  },
)

// ORGANIZATION

export const setOrganization = produce((draft, { organization }) => {
  draft.organization = organization
})

// ORGANIZATION TASKS

const reduceOrganizationTasks = (draft, tasks) => {
  draft.organizationTasksById = {
    ...draft.organizationTasksById,
    ...keyBy(tasks, 'id'),
  }
}

export const setOrganizationTask = produce((draft, { task }) => {
  draft.organizationTasksById[task.id] = task
})

// PEOPLE

export const addPeople = produce((draft, { people }) => {
  draft.peopleById = draft.peopleById || {}
  const byId = draft.peopleById
  for (const person of people) {
    byId[person.id] = person
  }
})

// PREFERENCES

export const setPreferences = produce((draft, changes) => {
  patchIn(draft.preferences, changes)
})

// SECURITY SETTINGS

export const setSingleSignOn = produce((draft, { singleSignOn }) => {
  const { singleSignOns } = draft.securitySettings

  // Remove any prior settings
  remove(singleSignOns, (sso) => sso.providerId === singleSignOn.providerId)

  // Add the new settings
  singleSignOns.push(singleSignOn)
})

export const setSecuritySettings = produce((draft, { securitySettings }) => {
  draft.securitySettings = securitySettings
})

// Root -----------------------------------------------------------------------

const rootReducers = {
  audienceDashboardSettings: reduceAudienceDashboardSettings,
  emailDomains: reduceEmailDomains,
  emailSettings: reduceEmailSettings,
  emailTemplates: reduceEmailTemplates,
  groups: reduceGroups,
  handbook: reduceHandbook,
  handbooks: reduceHandbooks,
  handbookReminders: reduceHandbookReminders,
  integrations: reduceIntegrations,
  organizationTasks: reduceOrganizationTasks,
}

export const mergeRoot = produce((draft, { data }) => {
  const keys = Object.keys(data)
  for (const key of keys) {
    const reducer = rootReducers[key]
    const value = data[key]
    if (!reducer) {
      set(draft, key, value)
    } else {
      reducer(draft, value)
    }
  }
})
