import { documentPermissions } from './document'
import { handbookPermissions } from './handbook'
import { organizationPermissions } from './organization'
import { superUserPermissions } from './superUser'
import { teamPermissions } from './team'
import { mapById } from './util'

export const permissions = [
  ...superUserPermissions,
  ...organizationPermissions,
  ...teamPermissions,
  ...documentPermissions,
  ...handbookPermissions,
]

export const permissionsById = mapById(permissions)
