export * from './useFeatureFlagEnabled'
export * from './useFeatureFlagIds'
export * from './useMetadataKeys'
export * from './useSavedSegments'
export * from './useSession'
export * from './useSetFeatureFlag'
export * from './useTeam'
export * from './useBlissbookAbility'
export * from './useTeamById'
export * from './useTeammate'
export * from './useTeams'

import type { Group } from '@blissbook/common/group'
import Handbook from '@blissbook/common/handbook'
import type { Integration } from '@blissbook/common/integration'
import { getPeopleById, setPreference } from '@blissbook/ui/application/actions'
import { getPeople } from '@blissbook/ui/application/api'
import {
  type PeopleQueryVariables,
  useHandbookQuery,
} from '@blissbook/ui/application/graph'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { useStore } from '@blissbook/ui/util/store'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { dispatch } from '../store'

// Hook to debounce setting a value
export const useDebouncedState = <T>(
  value: T,
  setValue: (value: T) => void,
  wait = 100,
) => {
  const [currValue, setCurrValue] = useState<T>(value)

  // If the incoming value changes, take that
  useEffect(() => {
    setCurrValue(value)
  }, [value])

  // Debounce the setValue
  const setValueDebounced = useCallback(debounce(setValue, wait), [setValue])

  // Helper to change the value
  const onChangeValue = (value: T) => {
    setCurrValue(value)
    setValueDebounced(value)
  }

  return [currValue, onChangeValue] as const
}

// Hook to take a subset of any array
export function useSubset<T>(array: T[], maxCount: number) {
  const [showAll, setShowAll] = useState(false)

  const showArray = useMemo(
    () => (showAll ? array : array.slice(0, maxCount)),
    [array, maxCount, showAll],
  )

  const hiddenArray = useMemo(() => {
    if (showArray.length === array.length) return
    return array.slice(maxCount)
  }, [array.length, showArray.length])

  const showHiddenArray = useCallback(() => setShowAll(true), [])

  return [showArray, hiddenArray, hiddenArray && showHiddenArray] as const
}

// Audience Dashboard Settings
export const useAudienceDashboardSettings = () =>
  useStore('audienceDashboardSettings')

// Custom Domain
export const useCustomDomain = () => useStore('customDomain')

// Email
export const useEmailDomainsById = () => useStore('emailDomainsById')

export const useEmailDomains = () => {
  const byId = useEmailDomainsById()
  return useMemo(() => Object.values(byId), [byId])
}

export const useEmailSettings = () => useStore('emailSettings')

export const useEmailTemplatesById = () => useStore('emailTemplatesById')

export const useEmailTemplate = (id: string) => {
  const byId = useEmailTemplatesById()
  return byId[id]
}

// Groups
const getGroupById = (state: any, groupId: number) => state.groupsById[groupId]

export const useGroup = (groupId: number) =>
  useStore((state) => getGroupById(state, groupId))

export const useGroupsById = () =>
  useStore('groupsById') as Record<number, Group>

export function useGroups() {
  const groupsById = useStore('groupsById')
  return Object.values(groupsById)
}

// Handbooks
export const useHandbooks = () => {
  const byId = useHandbooksById()
  return useMemo(() => Object.values(byId), [byId])
}

export const useHandbooksCount = () =>
  useStore((store) => {
    return Object.keys(store).length
  })

export const useHandbook = (handbookId: number) =>
  useStore(`handbooksById[${handbookId}]`) as IHandbook

export const useHandbooksById = () =>
  useStore('handbooksById') as Record<number, IHandbook>

export const useHandbookSection = (handbookId: number, sectionId: number) =>
  useStore(`handbooksById[${handbookId}].sectionsById[${sectionId}]`)

export function useHandbookStoreQuery(handbookId: number) {
  // From GraphQL
  const query = useHandbookQuery({
    fetchPolicy: 'no-cache',
    variables: { handbookId },
  })
  const { data, loading } = query

  // To the store
  useEffect(() => {
    if (!data) return

    const handbook = Handbook.fromJSON(data.handbook)
    dispatch('setHandbook', { handbook })
  }, [data])

  const handbook = useHandbook(handbookId)
  const loaded = !loading && handbook.sections !== undefined
  return {
    ...query,
    handbook,
    loaded,
  }
}

// Handbook Reminders
export const useHandbookRemindersById = () => useStore('handbookRemindersById')

export const useHandbookReminders = () => {
  const byId = useHandbookRemindersById()
  return useMemo(() => Object.values(byId), [byId])
}

// Integrations
export const useIntegrationsById = () =>
  useStore('integrationsById') as Record<number, Integration>

export function useIntegrations() {
  const byId = useIntegrationsById()
  return useMemo(() => Object.values(byId), [byId])
}

export function useIntegration() {
  const integrations = useIntegrations()
  return integrations[0]
}

// Invoices
export const useInvoicesById = () => useStore('invoicesById')

export const useInvoices = () => {
  const byId = useInvoicesById()
  return useMemo(() => Object.values(byId), [byId])
}

export const useInvoice = (invoiceId: number) => {
  const invoicesById = useInvoicesById()
  return invoicesById[invoiceId]
}

// Manager Digest
export const useManagerDigestSettings = () => useStore('managerDigestSettings')

// Organization
export const useOrganization = () => useStore('organization') as IOrganization

// Organization Tasks
export const useOrganizationTasksById = () => useStore('organizationTasksById')

// People
const getPersonById = (state: any, personId: number) =>
  state.peopleById[personId] as IPerson

export const usePerson = (personId: number) =>
  useStore((state) => getPersonById(state, personId)) as IPerson

export const usePeopleById = () =>
  useStore('peopleById') as Record<number, IPerson>

export const useLoadPerson = (personId: number) => {
  const [loading, setLoading] = useState(false)
  const person = usePerson(personId)

  useEffect(() => {
    if (!person && personId) {
      setLoading(true)
      getPeopleById([personId])
        .catch(logUIError)
        .finally(() => {
          setLoading(false)
        })
    }
  }, [personId])

  return { loading, person }
}

export function usePeople(props: PeopleQueryVariables, deps?: any[]) {
  const [people, setPeople] = useState<IPerson[]>()

  useEffect(() => {
    const { ids } = props
    if (Array.isArray(ids) && !ids.length) {
      setPeople([])
    } else {
      setPeople(undefined)
      getPeople(props).then(setPeople).catch(logUIError)
    }
  }, deps || Object.values(props))

  return people
}

// Show the name of this person, typically in a whodunnit situation
export const usePersonName = (id: number, showAdmin = false) => {
  const { person } = useLoadPerson(id)
  if (id) {
    return person ? person.fullName : '...'
  }
  if (showAdmin) {
    return 'Blissbook Admin'
  }
}

// Preferences
export function usePreference<T = any>(key: string) {
  const value = useStore(`preferences.${key}`) as T

  const setValue = useCallback(
    (newValue: T) => setPreference(key, newValue),
    [key],
  )

  return [value, setValue] as const
}

// Security settings
export const useSecuritySettings = () => useStore('securitySettings')
