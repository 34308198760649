import {
  type Document,
  type DocumentCollaborator,
  defineBlissbookAbility,
} from '@blissbook/lib/rbac'
import { useSession } from './useSession'

export function useBlissbookAbility({
  documents = [],
}: {
  documents?: (Document & {
    collaborators: DocumentCollaborator[]
  })[]
} = {}) {
  const { person, personId, superUser } = useSession()
  const documentCollaborators = documents
    .flatMap((document) => document.collaborators)
    .filter((collaborator) => collaborator.personId === personId)

  return defineBlissbookAbility({
    documents,
    documentCollaborators,
    person,
    superUser,
    teammate: person?.teammates[0],
  })
}
