import { FeatureFlagId } from '@blissbook/ui/application/graph'

export type FeaturesPlan = {
  id: string
  name: string
  featureFlagIds: string[]
}

export const blissbookPlan: FeaturesPlan = {
  id: 'Blissbook-2025',
  name: 'Blissbook',
  featureFlagIds: [
    FeatureFlagId.AllowMergeDev,
    FeatureFlagId.ShowPolicyLibrary,
  ],
}

export const blissbookProPlan: FeaturesPlan = {
  id: 'Blissbook-PRO-2025',
  name: 'Blissbook Pro',
  featureFlagIds: [
    ...blissbookPlan.featureFlagIds,
    FeatureFlagId.AllowManagers,
  ],
}

const complianceProPlan: FeaturesPlan = {
  id: 'Compliance-Pro',
  name: 'Compliance Pro',
  featureFlagIds: [FeatureFlagId.AllowManagers],
}

export const plans = [blissbookPlan, blissbookProPlan, complianceProPlan]

export const plansById = new Map(plans.map((plan) => [plan.id, plan]))

const addOnsById: Record<string, FeatureFlagId[]> = {
  'Employee-Sync-HRISAD-Integration': [FeatureFlagId.AllowMergeDev],
  'Automated-Manager-Escalation': [FeatureFlagId.AllowManagers],
}

type FeaturesSubscription = {
  addOnIds: string[]
  planId?: string | null
}

/** Get the feature flags that are included in this subscription */
export function getSubscriptionFeatureFlagIds(
  subscription: FeaturesSubscription | null,
  featureFlagIds = new Set<string>(),
) {
  if (!subscription) return featureFlagIds

  // Add features from the plan
  const plan = plansById.get(subscription.planId)
  if (plan) {
    plan.featureFlagIds.forEach((id) => featureFlagIds.add(id))
  }

  // Add features from the add-ons
  for (const addOnId of subscription.addOnIds) {
    const addOn = addOnsById[addOnId]
    if (!addOn) continue
    addOn.forEach((id) => featureFlagIds.add(id))
  }

  return featureFlagIds
}

export function getFeatureFlagIds(
  featureFlagIds: string[],
  subscription?: FeaturesSubscription | null,
) {
  const result = new Set(featureFlagIds)
  if (subscription) {
    getSubscriptionFeatureFlagIds(subscription, result)
  }
  return result
}
