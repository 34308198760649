import {
  type MongoAbility,
  type MongoQuery,
  defineAbility,
  subject,
} from '@casl/ability'
import httpError from 'http-errors'
import { startCase } from 'lodash'
import {
  type Document,
  type DocumentCollaborator,
  getDocumentCollaboratorRoles,
  getDocumentCollaboratorRolesFromTeammate,
} from './document'
import { type Person, getPersonRoles } from './organization'
import { type SuperUser, getSuperUserRoles } from './superUser'
import { type Teammate, getTeammateRoles } from './team'
import type { Role } from './types'

/** Convert Blissbook's subject.action permissionId to [action,subject] */
function parsePermissionId(permissionId: string) {
  const parts = permissionId.split('.')
  const ability = parts.pop()
  const subject = parts.join('.')
  return [ability, subject] as const
}

export class BlissbookAbility {
  constructor(private readonly ability: MongoAbility) {}

  can(action: string, modelName: string, conditions?: object) {
    return this.ability.can(
      action,
      conditions ? subject(modelName, conditions) : modelName,
    )
  }

  assert(action: string, modelName: string, conditions?: object) {
    if (this.can(action, modelName, conditions)) return

    const parts = ['Cannot', action, startCase(modelName).toLowerCase()]
    if (conditions) {
      const target = Object.entries(conditions)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ')
      parts.push(`on ${target}`)
    }

    const message = parts.join(' ')
    throw new httpError.Forbidden(message)
  }
}

export function defineBlissbookAbility({
  documents = [],
  documentCollaborators = [],
  person,
  superUser,
  teammate,
}: {
  documents?: Document[]
  documentCollaborators?: DocumentCollaborator[]
  person?: Person | undefined
  superUser?: SuperUser | undefined
  teammate?: Teammate | undefined
}) {
  const ability = defineAbility((can) => {
    /** Utility to add a role to the ability */
    function canRole(role: Role, conditions?: MongoQuery) {
      for (const permissionId of role.permissionIds) {
        const [ability, subject] = parsePermissionId(permissionId)
        can(ability, subject, conditions)
      }
    }

    /** Utility to add roles to the ability */
    function canRoles(roles: Role[], conditions?: MongoQuery) {
      for (const role of roles) {
        canRole(role, conditions)
      }
    }

    // Super user roles
    if (superUser) {
      const superUserRoles = getSuperUserRoles(superUser)
      canRoles(superUserRoles)
    }

    // Person roles
    if (person) {
      const personRoles = getPersonRoles(person)
      canRoles(personRoles)
    }

    // Team roles
    if (teammate) {
      const teamRoles = getTeammateRoles(teammate)
      canRoles(teamRoles)

      // Inherited Document roles
      const roles = getDocumentCollaboratorRolesFromTeammate(teammate)
      for (const document of documents) {
        if (!document.inheritTeamRoles) continue
        canRoles(roles, { documentId: document.id })
      }
    }

    // Document collaborator roles
    for (const collaborator of documentCollaborators) {
      const roles = getDocumentCollaboratorRoles(collaborator)
      const { documentId } = collaborator
      canRoles(roles, { documentId })
    }
  })
  return new BlissbookAbility(ability)
}
