import type { BlissbookAbility } from '../BlissbookAbility'
import type { Role } from '../types'
import { mapById } from '../util'
import { documentPermissions } from './documentPermissions'

export const DocumentCollaboratorRoleIds = [
  'viewer',
  'commenter',
  'editor',
  'publisher',
  'fullAccess',
] as const

export type DocumentCollaboratorRoleId =
  (typeof DocumentCollaboratorRoleIds)[number]

export type DocumentCollaboratorRole = Role & {
  id: DocumentCollaboratorRoleId
  accessText: string
}

// Team permissions for any role on a document
export const documentCollaboratorTeamRole: Role = {
  label: 'Document Collaborator',
  permissionIds: ['documents.create', 'policyLibrary.view'],
}

export const documentViewerRole: DocumentCollaboratorRole = {
  id: 'viewer',
  label: 'Viewer',
  permissionIds: ['document.view'],
  accessText: 'viewer access',
}

export const documentCommenterRole: DocumentCollaboratorRole = {
  id: 'commenter',
  label: 'Commenter',
  description: 'Suggest and comment',
  permissionIds: [...documentViewerRole.permissionIds, 'document.comment'],
  accessText: 'comment access',
}

export const documentEditorRole: DocumentCollaboratorRole = {
  id: 'editor',
  label: 'Editor',
  description: 'Edit, suggest, and comment',
  permissionIds: [
    ...documentCommenterRole.permissionIds,
    'document.edit',
    'documentCollaborators.manage',
  ],
  accessText: 'edit access',
}

export const documentPublisherRole: DocumentCollaboratorRole = {
  id: 'publisher',
  label: 'Publisher',
  description: 'Publish, edit, suggest, and comment',
  permissionIds: [
    ...documentEditorRole.permissionIds,
    'document.publish',
    'documentReviews.manage',
  ],
  accessText: 'publish access',
}

export const documentFullAccessRole: DocumentCollaboratorRole = {
  id: 'fullAccess',
  label: 'Full Access',
  description: 'Publish, edit, suggest, comment, and share with others',
  permissionIds: documentPermissions.map((p) => p.id),
  accessText: 'full access',
}

export const documentCollaboratorRoles = [
  documentViewerRole,
  documentCommenterRole,
  documentEditorRole,
  documentPublisherRole,
  documentFullAccessRole,
]

export const documentCollaboratorRolesReverse = [
  ...documentCollaboratorRoles,
].reverse()

export const documentCollaboratorRolesById = mapById(documentCollaboratorRoles)

export function getDocumentShareRoles(
  ability: BlissbookAbility,
  conditions?: {
    documentId: number
  },
) {
  // If cannot manage document collaborators, then return empty
  if (!ability.can('manage', 'documentCollaborators')) return []

  // Document viewer and commenter are always available
  const roles = [documentViewerRole, documentCommenterRole]

  // Escalate via roles
  if (ability.can('edit', 'document', conditions)) {
    roles.push(documentEditorRole)
  }
  if (ability.can('publish', 'document', conditions)) {
    roles.push(documentPublisherRole)
  }
  if (ability.can('delete', 'document', conditions)) {
    roles.push(documentFullAccessRole)
  }

  return roles.reverse()
}
