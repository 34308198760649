import {
  type SuperUserRole,
  type SuperUserRoleId,
  superUserRolesById,
} from './superUserRoles'

export type SuperUser = {
  id: number
  roleId: SuperUserRoleId | null
}

export function getSuperUserRoles(superUser: SuperUser) {
  const roles: SuperUserRole[] = []
  const role = superUserRolesById.get(superUser.roleId)
  if (role) roles.push(role)
  return roles
}
