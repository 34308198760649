export type OrganizationFlag = {
  id: string
  label: string
  description: (organizationName: string) => string
}

export const organizationFlags: OrganizationFlag[] = [
  {
    id: 'isFullServe',
    label: 'Full Serve',
    description: (organizationName: string) =>
      `Don't show pro tips to ${organizationName}`,
  },
  {
    id: 'isAuthDisabled',
    label: 'Disable Authentication',
    description: (organizationName: string) =>
      `Disable authentication to ${organizationName}`,
  },
  {
    id: 'isCrmDisabled',
    label: 'Disable HubSpot Sync',
    description: (organizationName: string) =>
      `Disable sync from ${organizationName} to HubSpot`,
  },
  {
    id: 'isIntercomDisabled',
    label: 'Disable Intercom',
    description: (organizationName: string) =>
      `Disable customer support for ${organizationName}`,
  },
  {
    id: 'showPrivacyPolicy',
    label: 'Show Privacy Policy',
    description: () =>
      `Show link to Blissbook's privacy policy in document footers and in the audience dashboard`,
  },
]
