import {
  type TeamRole,
  type TeamRoleId,
  getTeamRole,
  teamReportingRole,
  teamSuperBrandingRole,
} from './teamRoles'

export type Teammate = {
  teamId: number
  personId: number
  roleId?: TeamRoleId | null
  canReport: boolean
  canSuperBrand: boolean
}

export function getTeammateRoles(teammate: Teammate) {
  const roles: TeamRole[] = []

  const teamRole = getTeamRole(teammate.roleId)
  roles.push(teamRole)

  if (teammate.canReport) roles.push(teamReportingRole)
  if (teammate.canSuperBrand) roles.push(teamSuperBrandingRole)

  return roles
}
