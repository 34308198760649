import type { Teammate } from '../team/Teammate'
import { teamRolesById } from '../team/teamRoles'
import {
  type DocumentCollaboratorRole,
  type DocumentCollaboratorRoleId,
  documentCollaboratorRolesById,
} from './documentRoles'

export interface DocumentCollaborator {
  documentId: number
  personId: number
  roleId: DocumentCollaboratorRoleId
}

export function getDocumentCollaboratorRoles(
  collaborator: DocumentCollaborator | undefined,
) {
  const roles: DocumentCollaboratorRole[] = []
  if (!collaborator) return roles

  const role = documentCollaboratorRolesById.get(collaborator.roleId)
  if (role) roles.push(role)

  return roles
}

export function getDocumentCollaboratorRolesFromTeammate(
  teammate: Teammate,
): DocumentCollaboratorRole[] {
  // Get the team role
  const teamRole = teamRolesById.get(teammate.roleId)
  if (!teamRole) return []

  // Check the document role
  const { documentsRole } = teamRole
  if (!documentsRole) return []

  return [documentsRole]
}
