import type { Permission } from '../types'
import { mapById } from '../util'

export const documentPermissions: Permission[] = [
  {
    id: 'document.view',
    action: 'view',
    label: 'View Documents',
    description: 'User can view the document',
  },
  {
    id: 'document.comment',
    action: 'edit',
    label: 'Comment on Documents',
    description: 'User can comment on the document',
  },
  {
    id: 'document.edit',
    action: 'edit',
    label: 'Edit Documents',
    description: 'User can edit the document',
  },
  {
    id: 'documentCollaborators.manage',
    action: 'manage',
    label: 'Manage Document Collaborators',
    description: 'User can manage document collaborators',
  },
  {
    id: 'documentReviews.manage',
    label: 'Manage Document Reviews',
    description: 'User can manage reviews on a document',
  },
  {
    id: 'document.publish',
    action: 'publish',
    label: 'Publish Documents',
    description: 'User can publish the document',
  },
  {
    id: 'document.archive',
    action: 'archive',
    label: 'Archive Documents',
    description: 'User can archive the document',
  },
  {
    id: 'document.delete',
    action: 'delete',
    label: 'Delete Documents',
    description: 'User can delete the document',
  },
]

export const documentPermissionsById = mapById(documentPermissions)
