import type { FeatureFlag } from '@blissbook/lib/features'
import type { FeatureFlagId } from '@blissbook/ui/application/graph'
import { useSetFeatureFlagMutation } from '@blissbook/ui/application/graph'
import { handleError } from '@blissbook/ui/util/errors'
import { addToast } from '@blissbook/ui/util/toaster'
import { useCallback } from 'react'

export function useSetFeatureFlag() {
  const [setFeatureFlag] = useSetFeatureFlagMutation({
    onError(error) {
      handleError(error)
    },
    update(cache, { data: { featureFlagIds } }) {
      cache.modify({
        fields: {
          featureFlagIds() {
            return featureFlagIds
          },
        },
      })
    },
  })

  return useCallback(
    (flag: FeatureFlag, enabled: boolean) =>
      setFeatureFlag({
        onCompleted() {
          addToast(`${flag.label} is now ${enabled ? 'enabled' : 'disabled'}`, {
            type: 'success',
          })
        },
        variables: {
          flagId: flag.id as FeatureFlagId,
          enabled,
        },
      }),
    [setFeatureFlag],
  )
}
