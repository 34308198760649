import { useMemo } from 'react'
import { useTeams } from './useTeams'

/** Hook to get a team by id */
export function useTeamById(teamId: number) {
  const teams = useTeams()
  return useMemo(
    () => teams.find((team) => team.id === teamId),
    [teams, teamId],
  )
}
