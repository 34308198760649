import {
  type PeopleSource,
  useMetadataKeysQuery,
} from '@blissbook/ui/application/graph'

export function useMetadataKeys(source?: PeopleSource) {
  const { data } = useMetadataKeysQuery({
    variables: { source },
  })
  return data?.metadataKeys || []
}
